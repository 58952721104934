(function ($) {
    $(document).ready(function () {
        //
        // Adiciona texto de mouseover nos links do menu de áreas
        // 
        $('.block-menu-areas .menu > li').each(function () {
            if (typeof $(this).children('a').attr('name') !== 'undefined') {
                var info = $(this).children('a').attr('name');
                $(this).append('<span class="info-text">' + info + '</span>');
            }
        });

        $(window).load(function () {
            if ($(window).width() < 1200) {
                var height = $(window).height() - 59;
                $('#block-menu-block-1 .menu-level-1, #block-block-29 #block-menu-menu-areas').css('height', height + 'px');
            } else {
                if ($(window).height() < 720) {
                    $("#block-menu-menu-areas ul li:first-child ul").hide();
                    $("#block-menu-menu-areas ul li:first-child span").css('background','#4C4C4C url("https://portal.fgv.br/sites/portal.fgv.br/themes/portalfgv/css/images/more-arrow-hover.png")no-repeat 90% center').css("cursor","pointer");
                    $("#block-menu-menu-areas ul li:first-child span").once('click-menu-lateral').on( "click", function() {
                        $(this).next().stop(true,true).slideToggle();
                    });
                }
            }
        });
        $(window).resize(function () {
            if ($(window).width() < 1200) {
                var height = $(window).height() - 59;
                $('#block-menu-block-1 .menu-level-1, #block-block-29 #block-menu-menu-areas').css('height', height + 'px');
            } else {
                if ($(window).height() < 720) {
                    $("#block-menu-menu-areas ul li:first-child ul").hide();
                    $("#block-menu-menu-areas ul li:first-child span").css('background','#4C4C4C url("https://portal.fgv.br/sites/portal.fgv.br/themes/portalfgv/css/images/more-arrow-hover.png")no-repeat 90% center').css("cursor","pointer");
                    $("#block-menu-menu-areas ul li:first-child span").once('click-menu-lateral').on( "click", function() {
                        $(this).next().stop(true,true).slideToggle();
                    });
                }
            }
        });

        //
        // Busca
        //
        $('.search-toggle').click(function () {
            $('.search-forms').fadeIn('fast', function () {
                $(this).show();
            });
            $('.radios input[value=fgv]').click();
        });
        $('#page > div:not("#nav-wrapper")').mouseover(function () {
            if ($('.search-forms').is(':visible')) {
                $('.search-forms').fadeOut('fast', function () {
                    $(this).hide();
                });
            }
        });
        $('.radios input').change(function () {
            if ($(this).val() == 'fgv') {
                var searchQuery = $('#block-block-1 input[type=text]').val();
                $('#block-multiblock-3 input[type=text]').val(searchQuery);
                $('#block-multiblock-3').show();
                $('#block-block-1').hide();
            } else {
                var searchQuery = $('#block-multiblock-3 input[type=text]').val();
                $('#block-block-1 input[type=text]').val(searchQuery);
                $('#block-block-1').show();
                $('#block-multiblock-3').hide();
            }
        });
        $(".page-user #header-wrapper").addClass("white");
        //CLOSE MENU MOBILE && OPEN MENU MOBILE
        $('.menu-close-icon').click(function () {
            $('html').removeClass('ohidden');
            $('#block-block-28').fadeOut();
        });
        $("#block-block-30").click(function () {
            $('html').addClass('ohidden');
            $("#block-block-28").fadeIn();
        });
        //MENU AREAS
        $("#block-block-28 #block-menu-block-1 ul li.menu-item-1494,#block-block-28 #block-menu-block-1 ul li.menu-item-1515").click(function () {
            $("#block-block-29").stop(true, true).animate({right: "+=100%"});
            $("html, body").animate({scrollTop: 0}, "slow");
        });
        $(".areas-close-icon").click(function () {
            $("#block-block-29").stop(true, true).animate({right: "-=100%"});
        });

        $("#block-block-29 ul li.esc.active-trail span.item-cinza,#block-block-29 ul li.esc.menu-item-1695 span.item-cinza").addClass("aberto");
        $("#block-block-29 ul li.esc span.item-cinza").click(function () {
            if ($(this).hasClass("aberto")) {
                //fechar
                $(this).removeClass("aberto").next("ul").stop(true, true).slideUp("slow");
            } else {
                //abrir
                $("#block-block-29 ul li.esc ul").stop(true, true).slideUp();
                $("#block-block-29 ul li.esc span.item-cinza").removeClass("aberto");
                $(this).addClass("aberto").next("ul").stop(true, true).slideDown("slow");
            }

        });
        /*============================================================================================================
         * GRÁFICOS INDICES
         =============================================================================================================*/
        if ($('.view-indices .grafico').length > 0) {
            $('.view-indices .grafico').each(function () {
                var grafico = $(this).children().attr('id');
                var labels = [];
                var valores = [];
                var color;
                if (grafico == 'igp10') {
                    color = '#3795a1';
                } else if (grafico == 'igpdi') {
                    color = '#966c4d';
                } else {
                    color = '#7f964d';
                }

                var domain = 'portal.fgv.br';
                var label = '% a.m.';
                var url = '//' + domain + '/indices/json/' + grafico;
                if (document.documentElement.lang !== 'pt-br' && document.getElementsByTagName('html')[0].getAttribute('xml:lang') !== 'pt-br') {
                    if(document.documentElement.lang == 'zh-hans'){
                        url = '//portal.fgv.br/cn/indices/json/' + grafico;
                        label = '% p.m.';
                    }else{
                        url = '//portal.fgv.br/' + document.documentElement.lang + '/indices/json/' + grafico;
                        label = '% p.m.';
                    }
                }
                $.ajax({
                    type: "GET",
                    url: url,
                    timeout: 45000,
                    contentType: "application/json",
                    dataType: "json",
                    success: function (data) {
                        data = JSON.stringify(data);
                        data = JSON.parse(data);
                        
                        for (var i = 0; i < data.length; i++) {
                            labels.push(data[i].indice.data.toLowerCase());
                            valores.push(data[i].indice.valor);
                        }
                       
                        var config = {
                            type: 'line',
                            data: {
                                labels: labels,
                                datasets: [{
                                        label: label,
                                        backgroundColor: color,
                                        borderColor: color,
                                        borderWidth: 1,
                                        data: valores,
                                        fill: false
                                    }]
                            }
                        };
                        
                        var context = document.getElementById(grafico).getContext("2d");
                        window.grafico = new Chart(context, config);
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                    }
                });
            });
        }
    }
    );
})(jQuery);


